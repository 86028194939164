@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.container {
    @include dFlex();
    @include flexColumn();
    @include alignItemsCenter();

    min-height: 100vh;

    .header {
        width: 100%;
    }
    .content {
        width: 100%;
    }
}

@include applyResponsive320() {
    .container {
        .header {
            margin-bottom: 0;
        }
        .content {
            padding: 0 13px 0 13px;
        }
    }
}

@include applyResponsive1024() {
    .container {
        .header {
            margin-bottom: 24px;
        }
        .content {
            padding: 0 58px 0 58px;
        }
    }
}
