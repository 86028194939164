@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.navigation {
    @include dFlex();
}

@include applyResponsive320() {
    .navigation {
        @include flexColumn();

        gap: 11px;
    }
}

@include applyResponsive1024() {
    .navigation {
        @include flexRow();
        @include flexWrap();
        @include justifyContentCenter();

        gap: 0;
    }
}
