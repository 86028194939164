@import 'styles/colors';
@import 'tools/styles/helpers';

.exportButton {
    @include defaultButton();
    @include borderRadius(4px);

    border: 1px solid $lightGray;

    font-style: normal;
    font-weight: 600;
    color: $darkGray;
    cursor: pointer;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;

    padding: 7px 8px;

    img {
        width: 17px;
        height: 17px;

        margin-right: 8px;
        margin-bottom: -4px;
    }
}
