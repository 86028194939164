@import 'styles/colors';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.primaryButton {
    @include defaultButton();

    background: $purple;

    cursor: pointer;
    color: $white;

    .icon {

    }
}

.isDisabled {
    background: $lightPurple;

    cursor: not-allowed;
}

.isGreen {
    background: $green;
}

.isRed {
    background: $red;
}

@include applyResponsive320() {
    .primaryButton {
        @include borderRadius(4px);

        .icon {
            margin-right: 8px;
            margin-bottom: -1px;
        }
    }
}

@include applyResponsive1024() {
    .primaryButton {
        @include borderRadius(4px);

        .icon {
            margin-right: 8px;
            margin-bottom: -1px;
        }
    }
}