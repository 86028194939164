@import 'styles/z';
@import 'styles/colors';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.dataCell {
    font-weight: 400;

    .tooltip {
        position: fixed;

        z-index: $entityTableTooltipZIndex;

        max-width: 400px;

        background: $white;
        border: 1px solid $lightGray;

        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
        overflow: hidden;

        padding: 10px;
    }
}

.isSingleLine {
    @include hiddenOverflowText();
}

.isMultiline {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
}

.isBold {
    font-weight: 700 !important;

    .tooltip {
        font-weight: 400 !important;
    }
}

@include applyResponsive320() {
    .dataCell {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15399999916553497px;

        .tooltip {
            padding: 10px;
        }
    }
}

@include applyResponsive1024() {
    .dataCell {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15399999916553497px;

        .tooltip {
            padding: 10px;
        }
    }
}
