@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.refreshButton {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();
    @include defaultButton();

    width: fit-content;
    height: fit-content;

    cursor: pointer;

    gap: 10px;

    .refreshImage {
        width: 20px;
        height: 20px;
    }

    @include hover() {
        color: $darkGray;

        .refreshImage {
            path {
                fill: $darkGray;
            }
        }
    }
    &:active {
        color: $purple;

        .refreshImage {
            path {
                fill: $purple;
            }
        }
    }
}
