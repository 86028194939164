@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.backButton {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();
    @include defaultButton();

    font-style: normal;
    font-weight: 600;
    cursor: pointer;

    .image {

    }
}

@include applyResponsive320() {
    .backButton {
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.15399999916553497px;

        .image {
            width: 24px;
            height: 24px;

            margin-right: 21px;
        }
    }
}

@include applyResponsive1024() {
    .backButton {
        font-size: 32px;
        line-height: normal;

        .image {
            width: 24px;
            height: 24px;

            margin-right: 20px;
        }
    }
}
