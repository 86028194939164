@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.entityModalMobile {
    @include borderRadius(20px);
    @include dFlex();
    @include flexColumn();

    min-width: 320px;
    width: 80vw;

    gap: 8px;

    padding: 15px 15px 100px 15px;

    .top {
        @include dFlex();
        @include flexRow();
        @include justifyContentBetween();
        @include alignItemsCenter();

        margin-bottom: 15px - 8px;

        .title {
            font-size: 24px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.15399999916553497px;
        }
    }
}
