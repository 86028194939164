@import 'styles/colors';
@import 'tools/styles/helpers';

.qr {
    @include borderRadius(4px);

    cursor: pointer;
    background: $white;
    border: 1px solid rgba(0, 66, 105, 0.28);

    padding: 10px;

    .image {
        width: 100%;
        height: 100%;
    }
}
