@import 'tools/styles/adaptive';

.formBlock {
    .title {

    }
    .content {

    }
}

@include applyResponsive320() {
    .formBlock {
        .title {
            margin-bottom: 10px;
        }
        .content {
            padding: 0 10px 0 10px;
        }
    }
}

@include applyResponsive1024() {
    .formBlock {
        .title {
            margin-bottom: 16px;
        }
        .content {
            padding: 0 84px 0 84px;
        }
    }
}
