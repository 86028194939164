@import 'styles/colors';
@import 'tools/styles/adaptive';

.tab {
    font-style: normal;
    font-weight: 600;
    cursor: pointer;

    border-bottom: 2px solid inherit;
}

.isActive {
    border-bottom: 2px solid $purple;
}

@include applyResponsive320() {
    .tab {
        font-size: 14px;
        line-height: 19.07px;

        padding-bottom: 10px;
    }
}

@include applyResponsive1024() {
    .tab {
        font-size: 14px;
        line-height: 19.07px;

        padding-bottom: 10px;
    }
}