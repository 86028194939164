@import 'styles/colors';
@import 'tools/styles/flex';

.separator {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();

    width: 100%;

    .line {
        width: 100%;
        height: 1px;

        background: $darkGray;
    }
    .label {
        color: $darkGray;
        white-space: nowrap;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.15399999916553497px;

        padding-left: 14px;
    }
}
