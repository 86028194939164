@import 'styles/z';
@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/animations';

.authInfo {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();

    position: relative;

    background: $lightLightGray;

    font-style: normal;
    font-weight: 400;
    cursor: pointer;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;

    padding: 8px 11px 8px 8px;

    .personImage {
        width: 15px;
        height: 15px;

        margin-right: 15px;
    }
    .arrowImage {
        @include easeTransition(transform);

        width: 11px;
        height: 6px;

        margin-left: 7px;
    }
    .options {
        position: absolute;

        top: calc(100% + 8px);
        right: 0;
        z-index: $optionsZIndex;

        background: $lightLightGray;
        box-shadow: 0 4px 4px 0 rgba(97, 96, 237, 0.20);

        width: 151px;

        padding: 12px;

        .option {
            @include borderRadius(8px);
            @include easeTransition();

            font-style: normal;
            font-weight: 400;

            font-size: 12px;
            line-height: 20px;
            letter-spacing: -0.154px;

            padding: 5px 7px 5px 7px;

            &:hover {
                background: $lightPurple;

                font-weight: 600;
            }
        }
        .line {
            width: 100%;
            height: 1px;

            background: $gray;

            margin: 5px 0;
        }
    }
}

.isOpened {
    .arrowImage {
        @include rotate();
    }
}