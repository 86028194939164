@import 'tools/styles/helpers';

.crossButton {
    @include defaultButton();

    width: fit-content;
    height: fit-content;

    cursor: pointer;

    .crossImage {
        width: 22px;
        height: 22px;
    }
}
