$white: #FFFFFF;
$black: #191A1A;

$blue: #1154D7;;
$purple: #6160ED;
$lightPurple: #ADADF9;
$lightLightPurple: #F5F4FF;

$red: #A82C00;
$lightRed: #F6C4B9;

$yellow: #A85100;
$lightYellow: #F6DEB9;

$green: #006908;
$lightGreen: #D7F7C2;

$lightLightGray: #F8FAFC;
$lightGray: #D9D9D9;
$gray: #ABABAB;
$darkGray: #6A7383;

$bgGray: #F8FAFC;
