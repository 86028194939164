@import 'styles/colors';
@import 'tools/styles/helpers';
@import 'tools/styles/animations';

.arrowButton {
    width: 8px;
    height: 14px;

    cursor: pointer;

    path {
        @include easeTransition(fill);
    }
}

.isLeft {
    @include rotate();
}

.isDisabled {
    cursor: not-allowed;

    path {
        fill: $lightGray;
    }
}
