@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.header {
    @include dFlex();
    @include flexRow();
    @include justifyContentBetween();
    @include alignItemsCenter();

    .left {
        @include dFlex();
        @include flexRow();
        @include alignItemsCenter();

        .navigationMobile {

        }
        .logo {

        }
        .navigation {

        }
    }
}

@include applyResponsive320() {
    .header {
        padding: 22px 17px 28px 17px;

        .left {
            .navigationMobile {
                display: block;

                margin-right: 18px;
            }
            .logo {
                margin-right: 0;
            }
            .navigation {
                display: none;
            }
        }
    }
}

@include applyResponsive1024() {
    .header {
        border-bottom: 1px solid $lightGray;

        padding: 27px 58px 13px 58px;

        .left {
            .navigationMobile {
                display: none;

                margin-right: 0;
            }
            .logo {
                margin-right: 57px;
            }
            .navigation {
                @include dFlex();
            }
        }
    }
}
