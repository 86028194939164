@import 'styles/colors';
@import 'tools/styles/helpers';

.simpleButton {
    @include defaultButton();

    cursor: pointer;
    color: $blue;

    .icon {
        margin-right: 12px;
    }
}

.isDisabled {
    cursor: not-allowed;
}
