@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/animations';
@import 'tools/styles/adaptive';

.tabs {
    @include dFlex();
    @include flexRow();
    @include easeTransition(border-bottom);

    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid $lightGray;
    }
}

@include applyResponsive320() {
    .tabs {
        gap: 20px;
    }
}

@include applyResponsive1024() {
    .tabs {
        gap: 36px;
    }
}
