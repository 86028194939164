@import 'styles/z';
@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.entityTable {
    .head {
        @include dFlex();
        @include flexRow();

        .headGrid {
            width: 100%;

            .headCell {
                @include hiddenOverflowText();

                font-style: normal;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
        .optionsFiller {
            max-width: fit-content;
        }
    }
    .row {
        border-top: 1px solid $lightGray;
        border-bottom: 1px solid $lightGray;
    }
    .cell {
        position: relative;
    }
}

@include applyResponsive320() {
    .entityTable {
        .head {
            gap: 8px;

            .headGrid {
                .headCell {
                    font-size: 11px;
                    line-height: 20px;
                    letter-spacing: 0.34px;
                }
            }
            .optionsFiller {
                min-width: 24px;
                width: 24px;
            }
        }
        .row {
            padding: 10px;
        }
        .cell {
            padding: 4px 5px 4px 5px;
        }
    }
}

@include applyResponsive1024() {
    .entityTable {
        .head {
            gap: 16px;

            .headGrid {
                .headCell {
                    font-size: 11px;
                    line-height: 20px;
                    letter-spacing: 0.34px;
                }
            }
            .optionsFiller {
                min-width: 24px;
                width: 24px;
            }
        }
        .row {
            padding: 0;
        }
        .cell {
            padding: 10px;
        }
    }
}
