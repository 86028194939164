@import 'styles/colors';
@import 'tools/styles/helpers';

.valueDisplay {
    position: relative;

    .label {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.15000000596046448px;

        margin-bottom: 6px;
    }
    .value {
        @include borderRadius(4px);

        border: 1px solid $gray;

        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.15000000596046448px;
        word-break: break-all;

        padding: 8px 9px 8px 9px;
    }
    .copy {
        position: absolute;

        top: 11px;
        right: 13px;

        color: $blue;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
    }
    .copied {
        position: absolute;

        top: 11px;
        right: 13px;

        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: $green;
        cursor: pointer;

        img {
            width: 13px;
            height: 9px;

            margin-right: 7px;
        }
    }
}
