@import 'styles/colors';

.dateTimeDisplay {
    font-style: normal;
    font-weight: 400;

    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.154px;

    .date {

    }
    .time {
        color: $gray;
    }
}
