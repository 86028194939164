@import 'styles/colors';
@import 'styles/z';
@import 'tools/styles/helpers';

.dotsButton {
    @include defaultButton();

    position: relative;

    width: fit-content;
    height: fit-content;

    cursor: pointer;

    padding: 5px;

    .image {
        width: 12px;
        height: 3px;
    }
    .options {
        @include borderRadius(8px);

        position: absolute;

        top: 100%;
        right: 0;
        z-index: $optionsZIndex;

        background: $lightLightGray;
        border: 1px solid rgba(0, 66, 105, 0.28);

        .option {
            @include borderRadius(8px);
            @include removeLinkUnderline();

            display: block;

            font-style: normal;
            font-weight: 400;
            color: $black;
            white-space: nowrap;

            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.15px;
            cursor: pointer;

            padding: 5px 7px 5px 7px;

            &:hover {
                background: $lightPurple;
            }
        }
    }
}

.isEmpty {
    cursor: not-allowed;
}
