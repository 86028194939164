@import 'styles/colors';

.blockTitle {
    border-bottom: 1px solid $lightGray;

    font-style: normal;
    font-weight: 600;

    font-size: 18px;
    line-height: normal;

    padding-bottom: 12px;
}
