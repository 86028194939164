@import 'styles/z';
@import 'styles/colors';
@import 'tools/styles/flex';

.overlay {
    @include dFlex();
    @include flexRow();
    @include justifyContentCenter();
    @include alignItemsCenter();

    position: fixed;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $modalZIndex;

    background: rgba(0, 0, 0, 0.5);

    .container {
        position: absolute;

        width: fit-content;
        height: 90vh;

        overflow-y: auto;

        .modal {
            background: $white;
        }
    }
}
