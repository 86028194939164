@import 'styles/colors';

* {
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased !important;
    -webkit-text-size-adjust: 100%;

    color: $black;
    background: $bgGray;

    margin: 0;
}

input, textarea, button {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased !important;
}
