@import 'styles/z';
@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/animations';
@import 'tools/styles/adaptive';

.collapseToggleMobile {
    @include dFlex();
    @include flexColumn();
    @include alignItemsCenter();

    cursor: pointer;

    .text {
        position: relative;
        z-index: $toggleButtonTextZIndex;

        background: $lightLightGray;

        color: $darkGray;
        font-weight: 600;
        font-size: 10px;
        line-height: 20px;
        letter-spacing: -0.15399999916553497px;

        padding: 0 14px 0 14px;

        .arrowImage {
            width: 9px;
            height: 3px;

            margin-left: 10px;
        }
    }
    .line {
        width: 100%;
        height: 1px;

        background: $darkGray;

        margin-top: -11px;
    }
}

.isOpen {
    .text {
        .arrowImage {
            @include rotate();
        }
    }
}