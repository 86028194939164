@import 'styles/colors';

.errorMessage {
    font-style: normal;
    font-weight: 300;

    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: $red;
}

.isSmall {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
}
