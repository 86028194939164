@import 'tools/styles/animations';

.staticCollapse {
    @include easeTransition(height);

    overflow: hidden;
}

.isOpen {
    overflow: initial;
}
