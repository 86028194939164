@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.pagination {
    @include dFlex();

    .pages {
        @include dFlex();
        @include flexRow();
        @include alignItemsCenter();

        .page {
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
        }
        .currentPage {
            @include dFlex();
            @include flexRow();
            @include justifyContentCenter();
            @include alignItemsCenter();
            @include borderRadius(50%);

            font-style: normal;
            font-weight: 700;
            color: $white;
            cursor: default;

            background: $purple;
        }
    }
}

@include applyResponsive320() {
    .pagination {
        @include flexColumn();
        @include alignItemsCenter();

        gap: 20px;

        .pages {
            gap: 15px;

            .page {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.154px;
            }
            .currentPage {
                min-width: 36px;
                height: 36px;

                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.154px;
            }
        }
    }
}

@include applyResponsive1024() {
    .pagination {
        @include flexRow();
        @include justifyContentEnd();
        @include alignItemsCenter();

        gap: 46px;

        .pages {
            gap: 32px;

            .page {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.154px;
            }
            .currentPage {
                min-width: 36px;
                height: 36px;

                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.154px;
            }
        }
    }
}
