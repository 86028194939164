@import 'styles/colors';
@import 'tools/styles/adaptive';

.emptyResult {
    font-style: normal;
    font-weight: 600;
    color: $darkGray;
}

@include applyResponsive320() {
    .emptyResult {
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.34px;
    }
}

@include applyResponsive1024() {
    .emptyResult {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.154px;
    }
}
