@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.row {
    @include dFlex();
    @include flexRow();

    .content {

    }
    .action {
        @include dFlex();
        @include flexRow();
        @include justifyContentCenter();
        @include alignItemsCenter();

        .dotsButton {

        }
    }
}

@include applyResponsive320() {
    .row {
        gap: 8px;

        .content {
            width: 100%;
        }
        .action {
            min-width: 24px;
            width: 24px;

            .dotsButton {
                margin-top: -8px;
            }
        }
    }
}

@include applyResponsive1024() {
    .row {
        gap: 16px;

        .content {
            width: 100%;
        }
        .action {
            min-width: 24px;
            width: 24px;

            .dotsButton {
                margin-top: -8px;
            }
        }
    }
}
