@import 'styles/colors';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.pageInput {
    @include dFlex();
    @include flexRow();
    @include justifyContentBetween();
    @include alignItemsCenter();
    @include borderRadius(8px);

    position: relative;

    width: 107px;
    height: 36px;

    gap: 15px;

    border: 1px solid $gray;

    cursor: text;

    padding: 0 9px;

    .label {
        position: absolute;

        top: -6px;
        left: 10px;

        background: $white;

        font-style: normal;
        font-weight: 600;
        color: $gray;

        font-size: 12px;
        line-height: normal;

        padding: 0 5px;
    }
    .input {
        @include defaultInput();

        width: 66px;

        font-style: normal;
        font-weight: 600;
        color: $gray;

        font-size: 12px;
        line-height: normal;
    }
}
